.reel-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
  }

.program-wrapper {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 80%;
    height: 47%;
}

@media screen and (max-width: 800px) {
  .reel-wrapper {
    height: initial;
    margin-bottom: clamp(1rem, 14vw, 20rem);
  }
}