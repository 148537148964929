@font-face {
  font-family: "Nevrada";
  src: url("../fonts/AL_Nevrada_Personal_Use_Only.ttf");
}

.app-cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dl-head {
  height: 7.7rem;
  font-family: "Nevrada", "Serif";
  color: #446264;
  font-size: 6rem;
  margin: 0;
  margin-top: 6rem;
  letter-spacing: 0.02em;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.dl-sub {
  font-family: "Nevrada", "Serif";
  color: #ff717b;
  font-size: 1.33rem;
  margin-top: 0;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.s {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.s.one {
  height: 100vh;
}

.desk-s {
  height: 100vh;
}

.anim-cont {
  overflow: hidden;
}

.anim-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 20rem;
  width: 100vw;
  overflow: hidden;
}

.hidden {
  opacity: 0;
  width: 0;
}


@media all and (max-width: 450px) {
  .dl-head {
    font-size: 2.75rem;
    height: 3.5rem;
    margin-top: 0;
  }

  .dl-sub {
    font-size: 0.85rem;
  }

  .anim-cont {
    width: 45rem;
  }

  .anim-wrapper {
    margin-top: -6rem;
    overflow: hidden;
  }
}

@media all and (min-width: 450px) and (max-width: 1000px) {
  .dl-head {
    font-size: 4rem;
    height: 5rem;
    margin-top: 0;
  }

  .dl-sub {
    font-size: 0.9rem;
  }

  .anim-cont {
    width: 65rem;
  }
}

@media screen and (max-width: 800px) {
  .app-cont {
    height: initial;
  }
}

@media all and (min-width: 1000px) {
  .dl-head {
    height: 6rem;
    font-size: 6rem;
    margin: 0;
    margin-top: -12rem;
  }
  .dl-sub {
    margin-top: 3rem;
  }
  .anim-cont {
    width: 100rem;
  }
  .anim-wrapper {
    width: 100vw;
    -webkit-box-orient: initial;
    -webkit-box-direction: initial;
    -ms-flex-direction: initial;
    flex-direction: initial;
    overflow: initial;
  }
}
