.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2vw;
}

.form-item-holder {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: .3vw;
}

.form-item {
    width: clamp(3rem, 60vw, 50rem);
    font-size: clamp(1rem, 1vw, 2rem);
    padding: .5rem .5rem;
    border-radius: .8rem;
    border: 2px solid #446264;
    background-color: #F9FAF3;
}

.form-item.message {
    height: 10vw;
}

label {
    font-family: "Source Sans Pro", "Sans-Serif";
    font-weight: 400;
    font-size: clamp(.5rem, 1.4vw, 2rem);
    color: #446264;
}

.err-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.err-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.error {
    font-family: "Source Sans Pro", "Sans-Serif";
    font-weight: 400;
    font-size: clamp(.5rem, 2vw, 2rem);
    color: #ff717b;
}

.err-hidden {
    visibility: hidden;
}

.form-submit {
    width: 7vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: clamp(.5rem, 1vw, 4rem);
    border-radius: .5rem;
    border: 1px solid #446264;
    background-color: rgba(0,0,0,0);
    color: #446264;
    cursor: pointer;
    transition: .2s all ease-in-out;
}

.form-submit:hover {
    background-color: #3e5b5d1a;
    transform: scale(1.05);
}

@media screen and (max-width: 800px) {
    .form-wrapper {
        margin-bottom: clamp(1rem, 20vw, 16rem);
    }
}